<template>
    <div class="el-input el-input--small">
        <GmapAutocomplete
            v-if="ready"
            :placeholder="$t('common.start_typing')"
            :select-first-on-enter="true"
            class="el-input__inner"
            :options="{
                componentRestrictions: { country: country }
            }"
            @place_changed="getAddressDataFromGoogle"
        />
    </div>
</template>
<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
// import gmaps from '@google/maps';

Vue.use(VueGoogleMaps, {
    installComponents: true,
    load:              {
        key:       'AIzaSyCNIRpGZdH8CTBizW-HcGlAhnO_8YHvRNg',
        libraries: 'places,geometry',
        language:  'se',
    },
});

export default {
    data() {
        return {
            ready: false,
            data:  {
                postcode:         '',
                city:             '',
                street:           '',
                map_lat:          '',
                map_lng:          '',
                googleMapsClient: null,
            },
        };
    },

    computed: {
        countryVuex() {
            return this.$store.state.user.user.country;
        },
        country() {
            return this.countryVuex === 'Sweden' ? 'se' : 'pl';
        },
    },

    watch: {
        countryVuex() {
            this.ready = true;
        },
    },

    created() {
        if (this.$store.state.user.user.country) {
            this.ready = true;
        }
        if (this.iframe) {
            this.ready = true;
        }

        // this.googleMapsClient = gmaps.createClient({
        //     key: 'AIzaSyDHcBxOrSaCwYr2UEzeSbzqL3wPNDUtKyU'
        // });
    },

    methods: {
        getAddressDataFromGoogle(addressData) {
            if (addressData.geometry === undefined) {
                this.googleMapsClient.geocode({
                    address: addressData.name,
                }, (err, response) => {
                    if (!err) {
                        this.assignData(response.json.results[0]);
                    }
                });

                return false;
            }

            this.assignData(addressData);
        },

        assignData(addressData) {
            const addressComponents = {
                street_number: 'short_name',
                route:         'long_name',
                locality:      'long_name',
                postal_town:   'short_name',
                country:       'long_name',
                postal_code:   'short_name',
            };

            const parsedAddress = {};

            if (addressData.address_components !== undefined) {
                for (let i = 0; i < addressData.address_components.length; i += 1) {
                    const addressType = addressData.address_components[i].types[0];

                    if (addressComponents[addressType]) {
                        parsedAddress[addressType] = addressData.address_components[i][addressComponents[addressType]];
                    }
                }
            }

            const street = parsedAddress.route ? parsedAddress.route : '';
            const streetNumber = parsedAddress.street_number ? parsedAddress.street_number : '';

            this.data.street = !street && !streetNumber ? '' : `${street} ${streetNumber}`;
            this.data.city = parsedAddress.postal_town;
            this.data.postcode = parsedAddress.postal_code;
            this.data.map_lat = typeof addressData.geometry.location.lat === 'function'
                ? addressData.geometry.location.lat() : addressData.geometry.location.lat;
            this.data.map_lng = typeof addressData.geometry.location.lng === 'function'
                ? addressData.geometry.location.lng() : addressData.geometry.location.lng;

            this.$emit('placeFound', this.data);
        },
        resetInput() {
            this.ready = false;
            setTimeout(() => {
                this.ready = true;
            }, 500);
        },
    },
};
</script>
<style>

.el-input.el-input--medium input {
    box-sizing: border-box !important;
}

</style>
